import * as React from "react"
import { Helmet } from "react-helmet"

import "../styles/styles.css"
import Seo from "../components/seo"

import gillesImg from "../images/gilles.jpg"
import carlosImg from "../images/carlos.jpeg"
import annieChantaleImg from "../images/annie-chantale.jpg"
import mCDuvalImg from "../images/mc-duval.jpg"
import sebImg from "../images/sebastien.jpeg"
import gabrielThereseImg from "../images/gabriel-therese.jpg"
import jeanPhiImg from "../images/jean-philippe.jpeg"
import julieImg from "../images/julie.jpg"
import christianCatherineImg from "../images/christian-catherine.jpg"
import laurentImg from "../images/laurent.jpg"
import heleneImg from "../images/helene.jpg"
import remiImg from "../images/remi.jpeg"
import mCSalaunImg from "../images/mc-salaun.jpeg"
import steveImg from "../images/steve-djafa.jpg"
import Layout from "../components/layout"
import { Link } from "gatsby"

// markup
const PresentationPage = () => {
  const partenaires = [
    {
      prenom: "Gilles",
      nom: "Férant",
      photo: gillesImg,
      description: "Frère et prêtre dominicain, Iffendic (35)",
      ministere: "Bretagne louange",
      ministereHref: "https://bretagnelouange.weebly.com",
    },
    {
      prenom: "Carlos",
      nom: "Payan",
      photo: carlosImg,
      description: "Pasteur-missionnaire protestant-évangélique",
      ministere: "Paris tout est possible",
      ministereHref: "https://www.paristoutestpossible.org",
    },
    {
      prenom: "Rémi",
      nom: "Charpigny",
      photo: remiImg,
      ministere: "Paris tout est possible (44)",
      ministereHref: "https://www.paristoutestpossible.org",
    },
    {
      prenom: "Annie-Chantal",
      nom: "Diavita",
      photo: annieChantaleImg,
      description: "Angers (49)",
    },
    {
      prenom: "Marie-Christine",
      nom: "Duval",
      photo: mCDuvalImg,
      description: "Sulniac (56)",
      ministere: "Haberakha",
      ministereHref: "https://www.haberakha.com",
    },
    {
      prenom: "Laurent",
      nom: "Gay",
      photo: laurentImg,
      description: "Les herbiers (85)",
      ministere: "Site Web",
      ministereHref: "https://www.laurentgay.com",
    },
    {
      prenom: "Sébastien",
      nom: "Gil",
      photo: sebImg,
      description: "Cherbourg-en-Cotentin (50)",
      ministere: "Sénevé Cotentin",
      ministereHref: "https://seneve.org",
      ministere2: "Site Web",
      ministere2Href: "https://sebastiengil.com",
    },
    {
      prenom: "Gabriel et Thérèse",
      nom: "Godin",
      photo: gabrielThereseImg,
      // description: "Auteure-compositrice-interprète, Rennes (35)",
      ministere: "Breizh en feu",
      // ministereHref: "https://helenegoussebayle.fr/new/",
    },
    {
      prenom: "Hélène",
      nom: "Goussebayle",
      photo: heleneImg,
      description: "Auteure-compositrice-interprète, Rennes (35)",
      ministere: "Site Web",
      ministereHref: "https://helenegoussebayle.fr/new/",
    },
    {
      prenom: "Julie",
      nom: "Le Rouge",
      photo: julieImg,
      description: "Vallons-de-l'Erdre (44)",
      ministere: "Amen Toi",
      ministereHref: "https://amentoi.fr",
      ministere2: "La maison de David",
      ministere2Href: "https://lamaisondedavid.fr",
    },
    {
      prenom: "Jean-Philippe",
      nom: "Rouillier",
      photo: jeanPhiImg,
      description: "Montaigu-Vendée (85)",
      ministere: "Site Web",
      ministereHref: "https://jprouillier717.wordpress.com",
    },
    {
      prenom: "Marie-Christine",
      nom: "Salaün",
      photo: mCSalaunImg,
      description: "Morbihan (56)",
      ministere: "Fraternité Pentecôte Grand Ouest",
      ministereHref: "https://fraternitepentecote.fr",
    },
    {
      prenom: "Christian & Catherine",
      nom: "Vucher",
      photo: christianCatherineImg,
      description: "Morbihan (56)",
      ministere: "Breizh en feu",
    },
  ]
  const invites = [
    {
      prenom: "Steve",
      nom: "Djaffa",
      photo: steveImg,
      description: "Pasteur laïc à l'église Paris Évangile",
      ministere: "Église Paris Évangile",
      ministereHref: "https://parisevangile.fr",
      ministere2: 'Chaîne Youtube "Enracinés"',
      ministere2Href: "https://www.youtube.com/user/wonders5/videos",
    },
  ]
  const partenairesRows = partenaires.reduce(function (
    partenairesRows,
    key,
    index
  ) {
    return (
      (index % 2 === 0
        ? partenairesRows.push([key])
        : partenairesRows[partenairesRows.length - 1].push(key)) &&
      partenairesRows
    )
  },
  [])

  const invitesRows = invites.reduce(function (invitesRows, key, index) {
    return (
      (index % 2 === 0
        ? invitesRows.push([key])
        : invitesRows[invitesRows.length - 1].push(key)) && invitesRows
    )
  }, [])

  var impaire = true

  const setImpaire = _impaire => {
    impaire = _impaire
  }

  const Damier = array => (
    <div id="damier-partenaires">
      {array.map(row => (
        <div
          className={impaire ? "damier-ligne-impaire" : "damier-ligne-paire"}
        >
          {row.map(partenaire => (
            <div className="damier-cases">
              <div
                className={
                  "damier-case " + (partenaire.photo == null ? "gris" : "")
                }
              >
                {partenaire.photo != null ? (
                  <img
                    src={partenaire.photo}
                    alt={partenaire.prenom + " " + partenaire.nom}
                    placeholder="blurred"
                    layout="fixed"
                    width={300}
                    height={300}
                  />
                ) : null}
              </div>
              <div className="damier-case damier-case-nom">
                <h3>
                  {partenaire.prenom}
                  <br />
                  {partenaire.nom}
                </h3>
                {partenaire.description !== undefined ? (
                  <p style={{ marginBottom: "0px" }}>
                    {partenaire.description}
                  </p>
                ) : null}
                {partenaire.ministere !== undefined ? (
                  partenaire.ministereHref !== undefined ? (
                    <p style={{ marginBottom: "0px" }}>
                      <Link target="_blank" to={partenaire.ministereHref}>
                        {partenaire.ministere}
                      </Link>
                    </p>
                  ) : (
                    <p style={{ marginBottom: "0px" }}>
                      {partenaire.ministere}
                    </p>
                  )
                ) : null}
                {partenaire.ministere2 !== undefined ? (
                  partenaire.ministere2Href !== undefined ? (
                    <p style={{ marginBottom: "0px" }}>
                      <Link target="_blank" to={partenaire.ministere2Href}>
                        {partenaire.ministere2}
                      </Link>
                    </p>
                  ) : (
                    <p style={{ marginBottom: "0px" }}>
                      {partenaire.ministere2}
                    </p>
                  )
                ) : null}
              </div>
            </div>
          ))}
          {setImpaire(!impaire)}
        </div>
      ))}
    </div>
  )
  return (
    <Layout>
      <Helmet>
        <title>Présentation - Ensemble Grand Ouest</title>
      </Helmet>
      <Seo title="Présentation - Ensemble Grand Ouest"></Seo>
      <section className="section feuille-conteneur">
        <div className="feuille">
          <h2>Le collectif</h2>
          <p>
            Nous sommes des disciples du Christ issus de plusieurs dénominations
            chrétiennes (catholiques et protestants-évangéliques). Nous sommes
            ancrés dans le Grand-Ouest et désirons voir cette région transformée
            par le message de l'Évangile.{" "}
          </p>
          <p>{Damier(partenairesRows)}</p>
          {/* <h2>Les invités</h2>
          <p>{Damier(invitesRows)}</p> */}
        </div>
      </section>
    </Layout>
  )
}

export default PresentationPage
